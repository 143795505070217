import API, { graphqlOperation } from "@aws-amplify/api-graphql";
import { useEffect, useState } from "react";
import Observable, { ZenObservable } from "zen-observable-ts";
import {
    ModelSortDirection,
    ModelStringKeyConditionInput,
    OldDatum,
    OldDatumDaily,
    OldDatumHourly,
    OnCreateOldDatumDailySubscription,
    OnCreateOldDatumHourlySubscription,
    OnCreateOldDatumSubscription,
    OnUpdateOldDatumDailySubscription,
    OnUpdateOldDatumHourlySubscription,
    OnUpdateOldDatumSubscription,
} from "../../API";
import {
    onCreateOldDatum,
    onCreateOldDatumDaily,
    onCreateOldDatumHourly,
    onUpdateOldDatum,
    onUpdateOldDatumDaily,
    onUpdateOldDatumHourly,
} from "../../graphql/subscriptions";
import { Database } from "../../model/database";
import {
    SubscribeError,
    SubscribeEventData,
} from "../../model/database/lib/graphql";
import { useDatabase } from "./use-database";

export namespace useOldDataSelectively {
    /**
     * 個々のデータ
     */
    export type Datum = {
        /** OldDatum 等のレコードの ID */
        readonly id: string;
        /** レコードの種類 */
        readonly type: "OldDatum" | "OldDatumHourly" | "OldDatumDaily";
        /** X軸の値 (ミリ秒単位のエポックからの経過時間) */
        readonly x: number;
        /** Y軸の値 (`value` または `value.mid` 値) */
        readonly y: number | null;
        /** Y軸の最大値 (`value.max` 値) */
        readonly yMax?: number | undefined;
        /** Y軸の最小値 (`value.min` 値) */
        readonly yMin?: number | undefined;
    };

    /**
     * データ ID とそのデータ列
     */
    export type Data = {
        /** データ ID */
        readonly datumId: string;
        /** データ列 */
        readonly data: readonly Datum[];
    };
}

/**
 * ID と時間範囲を指定して、`OldDatum` から該当データを取得します。
 *
 * 時間範囲 `rangeInSec` が 50 時間以上であった場合は `OldDatumHourly` から、
 * 50 日以上であった場合は `OldDatumDaily` から取得します。
 * この切替は自動的に実施されます。
 *
 * 該当データが更新された場合、自動的に反映します。
 *
 * @param datumIds データ ID の配列
 * @param time 取得する時間範囲の終端
 * @param rangeInSec 取得する時間範囲 [秒]
 * @returns 取得したデータ
 */
export function useOldDataSelectively(
    datumIds: readonly (string | undefined)[],
    time: Date,
    rangeInSec: number,
): readonly (useOldDataSelectively.Data | undefined)[] {
    const database = useDatabase();
    const tick = time.getTime();
    const [result, setResult] = useState<
        readonly (useOldDataSelectively.Data | undefined)[]
    >(() =>
        datumIds.map((datumId) =>
            datumId === undefined ? undefined : { datumId, data: [] },
        ),
    );

    useEffect(() => {
        const ac = new AbortController();
        const fetch =
            rangeInSec < ThresholdH
                ? fetchOldData
                : rangeInSec < ThresholdD
                ? fetchOldDataHourly
                : fetchOldDataDaily;
        const observations =
            rangeInSec < ThresholdH
                ? [onCreateOldDatum, onUpdateOldDatum]
                : rangeInSec < ThresholdD
                ? [onCreateOldDatumHourly, onUpdateOldDatumHourly]
                : [onCreateOldDatumDaily, onUpdateOldDatumDaily];
        const start = new Date(tick - rangeInSec * 1000).toISOString();
        const end = new Date(tick).toISOString();
        const timeCriteria = { between: [start, end] };

        // データを取得する
        Promise.all(
            datumIds.map((datumId) =>
                datumId === undefined
                    ? undefined
                    : fetch(database, datumId, timeCriteria, ac.signal),
            ),
        )
            .then((result) => {
                if (ac.signal.aborted) {
                    return;
                }
                setResult(result);
            })
            .catch((error: unknown) => {
                if (ac.signal.aborted) {
                    return;
                }
                console.error(error);
            });

        // 変更を監視する
        const subscriptions = subscribe(observations, (id, time, datum) => {
            if (
                ac.signal.aborted ||
                time < start ||
                time > end ||
                !datumIds.includes(id)
            ) {
                return;
            }

            setResult((prev) => {
                const index = prev.findIndex((d) => d?.datumId === id);
                if (index === -1) {
                    return prev;
                }

                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- `findIndex` が -1 じゃない時点で null ではない
                const data = [...prev[index]!.data];
                upsert(data, datum);

                const next = [...prev];
                next[index] = { datumId: id, data };
                return next;
            });
        });

        return () => {
            ac.abort();
            for (const subscription of subscriptions) {
                subscription.unsubscribe();
            }
        };
    }, [database, datumIds, rangeInSec, tick]);

    return result;
}

//------------------------------------------------------------------------------
// Helpers
//------------------------------------------------------------------------------

const ThresholdH = 50 * 60 * 60; // 50 hours
const ThresholdD = 50 * 24 * 60 * 60; // 50 days

type SubscriptionEvent = SubscribeEventData<
    OnCreateOldDatumSubscription &
        OnCreateOldDatumHourlySubscription &
        OnCreateOldDatumDailySubscription &
        OnUpdateOldDatumSubscription &
        OnUpdateOldDatumHourlySubscription &
        OnUpdateOldDatumDailySubscription
>;

/**
 * `OldDatum` テーブルから外胴データを取得します。
 *
 * @param database データベース インスタンス
 * @param datumId データ ID
 * @param time 時間範囲
 * @param signal 中止シグナル
 * @returns 取得したデータ
 */
async function fetchOldData(
    database: Database,
    datumId: string,
    time: ModelStringKeyConditionInput,
    signal: AbortSignal,
): Promise<useOldDataSelectively.Data> {
    const oldData = await database.oldData.findGreedily(
        {
            datumId,
            index: "listOldDataByDatumId",
            sortDirection: ModelSortDirection.ASC,
            time,
        },
        { signal },
    );

    return { datumId, data: oldData.map(convertOldDatum) };
}

/**
 * `OldDatumHourly` テーブルから外胴データを取得します。
 *
 * @param database データベース インスタンス
 * @param datumId データ ID
 * @param time 時間範囲
 * @param signal 中止シグナル
 * @returns 取得したデータ
 */
async function fetchOldDataHourly(
    database: Database,
    datumId: string,
    time: ModelStringKeyConditionInput,
    signal: AbortSignal,
): Promise<useOldDataSelectively.Data> {
    const oldData = await database.oldDataHourly.findGreedily(
        {
            datumId,
            index: "listOldDataHourlyByDatumId",
            sortDirection: ModelSortDirection.ASC,
            time,
        },
        { signal },
    );

    return { datumId, data: oldData.map(convertOldDatumHourly) };
}

/**
 * `OldDatumDaily` テーブルから外胴データを取得します。
 *
 * @param database データベース インスタンス
 * @param datumId データ ID
 * @param time 時間範囲
 * @param signal 中止シグナル
 * @returns 取得したデータ
 */
async function fetchOldDataDaily(
    database: Database,
    datumId: string,
    time: ModelStringKeyConditionInput,
    signal: AbortSignal,
): Promise<useOldDataSelectively.Data> {
    const oldData = await database.oldDataDaily.findGreedily(
        {
            datumId,
            index: "listOldDataDailyByDatumId",
            sortDirection: ModelSortDirection.ASC,
            time,
        },
        { signal },
    );

    return { datumId, data: oldData.map(convertOldDatumDaily) };
}

/**
 * 変更監視を行います。
 *
 * @param observations 監視対象
 * @param onChange 変更イベント発火時に呼ばれるコールバック関数
 * @returns サブスクリプション
 */
function subscribe(
    observations: readonly string[],
    onChange: (
        datumId: string,
        time: string,
        datum: useOldDataSelectively.Datum,
    ) => void,
): ZenObservable.Subscription[] {
    return observations.map((observation) =>
        (
            API.graphql(
                graphqlOperation(observation),
            ) as Observable<SubscriptionEvent>
        ).subscribe(
            (event) => {
                const {
                    onCreateOldDatum,
                    onCreateOldDatumDaily,
                    onCreateOldDatumHourly,
                    onUpdateOldDatum,
                    onUpdateOldDatumDaily,
                    onUpdateOldDatumHourly,
                } = event.value.data;
                const oldDatum = onCreateOldDatum ?? onUpdateOldDatum;
                const oldDatumHourly =
                    onCreateOldDatumHourly ?? onUpdateOldDatumHourly;
                const oldDatumDaily =
                    onCreateOldDatumDaily ?? onUpdateOldDatumDaily;

                if (oldDatum != null) {
                    onChange(
                        oldDatum.datumId,
                        oldDatum.time,
                        convertOldDatum(oldDatum),
                    );
                } else if (oldDatumHourly != null) {
                    onChange(
                        oldDatumHourly.datumId,
                        oldDatumHourly.time,
                        convertOldDatumHourly(oldDatumHourly),
                    );
                } else if (oldDatumDaily != null) {
                    onChange(
                        oldDatumDaily.datumId,
                        oldDatumDaily.time,
                        convertOldDatumDaily(oldDatumDaily),
                    );
                }
            },
            (error: SubscribeError) => {
                console.error(error.error);
            },
        ),
    );
}

/**
 * `OldDatum` レコードを共通のデータ点オブジェクトに変換します。
 *
 * @param oldDatum `OldDatum` レコード
 * @returns データ点情報
 */
function convertOldDatum(oldDatum: OldDatum): useOldDataSelectively.Datum {
    return {
        id: oldDatum.id,
        type: "OldDatum",
        x: Date.parse(oldDatum.time),
        y: oldDatum.status >= 0 ? oldDatum.value ?? null : null,
    };
}

/**
 * `OldDatumHourly` レコードを共通のデータ点オブジェクトに変換します。
 *
 * @param oldDatum `OldDatum` レコード
 * @returns データ点情報
 */
function convertOldDatumHourly(
    oldDatum: OldDatumHourly,
): useOldDataSelectively.Datum {
    return {
        id: oldDatum.id,
        type: "OldDatumHourly",
        x: Date.parse(oldDatum.time),
        y: oldDatum.value?.mid ?? null,
        yMax: oldDatum.value?.max,
        yMin: oldDatum.value?.min,
    };
}

/**
 * `OldDatumDaily` レコードを共通のデータ点オブジェクトに変換します。
 *
 * @param oldDatum `OldDatum` レコード
 * @returns データ点情報
 */
function convertOldDatumDaily(
    oldDatum: OldDatumDaily,
): useOldDataSelectively.Datum {
    return {
        id: oldDatum.id,
        type: "OldDatumDaily",
        x: Date.parse(oldDatum.time),
        y: oldDatum.value?.mid ?? null,
        yMax: oldDatum.value?.max,
        yMin: oldDatum.value?.min,
    };
}

/**
 * 新しいデータを、配列の適切な位置に挿入します。
 *
 * @param data 変更対象の配列
 * @param newDatum 新しいデータ
 */
function upsert(
    data: useOldDataSelectively.Datum[],
    newDatum: useOldDataSelectively.Datum,
): void {
    for (let index = data.length - 1; index >= 0; --index) {
        const x = data[index].x;

        if (x === newDatum.x) {
            // 該当時刻のデータがあったので、差し替える
            data[index] = newDatum;
            return;
        }
        if (x < newDatum.x) {
            // 挿入位置が見つかったので、挿入する
            data.splice(index + 1, 0, newDatum);
            return;
        }
    }

    // 新しいデータは既存の全データより古かったので、先頭に追加する
    data.unshift(newDatum);
}
